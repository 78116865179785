export const locationsHindi = [
    'पार्क',
    'समुद्र तट',
    'संग्रहालय',
    'पुस्तकालय',
    'कॉफ़ी शॉप',
    'मूवी थिएटर',
    'मॉल',
    'चिड़ियाघर',
    'जिम',
    'खेल का मैदान',
    'स्टेडियम',
    'रेस्टोरेंट',
    'बार',
    'किराना स्टोर',
    'बेकरी',
    'स्कूल',
    'अस्पताल',
    'चर्च',
    'मस्जिद',
    'मंदिर',
    'झील',
    'नदी',
    'फार्म',
    'होटल',
    'मोटेल',
    'रिसॉर्ट',
    'मनोरंजन पार्क',
    'एक्वेरियम',
    'कसीनो',
    'बॉलिंग एली',
    'स्की रिसॉर्ट',
    'कैम्पग्राउंड',
    'हाइकिंग ट्रेल',
    'वनस्पति उद्यान',
    'अंगूर का बाग',
    'वाइनरी',
    'ब्रुअरी',
    'फूड ट्रक',
    'पिकनिक क्षेत्र',
    'बुकस्टोर',
    'आर्ट गैलरी',
    'कॉन्सर्ट हॉल',
    'नाइटक्लब',
    'कराओके बार',
    'कम्युनिटी सेंटर',
    'स्पोर्ट्स क्लब',
    'तरणताल',
    'स्पा',
    'ब्यूटी सैलून',
    'नाई की दुकान',
    'योग स्टूडियो',
    'डांस स्टूडियो',
    'बॉक्सिंग जिम',
    'टेनिस कोर्ट',
    'गोल्फ कोर्स',
    'ड्राइविंग रेंज',
    'शूटिंग रेंज',
    'एस्केप रूम',
    'ट्रैम्पोलिन पार्क',
    'रॉक क्लाइम्बिंग जिम',
    'पेंटबॉल एरेना',
    'गो-कार्ट ट्रैक',
    'लेज़र टैग एरेना',
    'मार्शल आर्ट्स स्टूडियो',
    'बेसबॉल फील्ड',
    'बास्केटबॉल कोर्ट',
    'सॉकर फील्ड',
    'टेनिस कोर्ट',
    'वॉलीबॉल कोर्ट',
    'रंगमंच',
    'सीनिक व्यूपॉइंट',
    'ऐतिहासिक स्थल',
    'राष्ट्रीय उद्यान',
    'प्राकृतिक आरक्षित क्षेत्र',
    'जंगल',
    'पर्वत',
    'पहाड़ी',
    'गुफा',
    'झरना',
    'हॉट स्प्रिंग',
    'रेगिस्तान',
    'गुफा',
    'लाइटहाउस',
    'खगोलीय वेधशाला',
    'किला',
    'हवेली',
    'महल',
    'पुल',
    'बांध',
    'बंदरगाह',
    'मरीना',
    'घाट',
    'हवाई अड्डा',
    'रेलवे स्टेशन',
    'बस स्टेशन',
    'मेट्रो स्टेशन',
    'टैक्सी स्टैंड',
    'कार रेंटल एजेंसी',
    'पेट्रोल पंप',
    'पार्किंग लॉट',
    'स्ट्रीट मार्केट',
    'शॉपिंग सेंटर',
    'आउटलेट मॉल',
    'किसान बाजार',
    'फ्ली मार्केट',
    'एंटीक स्टोर',
    'थ्रिफ्ट स्टोर',
    'सुपरमार्केट',
    'सुविधा स्टोर',
    'हार्डवेयर स्टोर',
    'पेट स्टोर',
    'खिलौना की दुकान',
    'संगीत की दुकान',
    'इलेक्ट्रॉनिक्स स्टोर',
    'फर्नीचर स्टोर',
    'होम इम्प्रूवमेंट स्टोर',
    'गार्डन सेंटर',
    'पेट ग्रूमिंग सैलून',
    'वेटरनरी क्लिनिक',
    'फार्मेसी',
    'डाकघर',
    'बैंक',
    'पिज़्ज़ेरिया',
    'आइसक्रीम की दुकान',
    'पुलिस स्टेशन',
    'फायर स्टेशन',
    'यूनिवर्सिटी कैंपस',
    'ऑफिस बिल्डिंग',
    'पौधा',
    'पनडुब्बी',
];

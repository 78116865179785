import { create } from 'zustand'
import { createGameState } from './game.helpers';
import {languages} from '../../locations/loactions-list';


interface GameState {
    players: any[],
    spies: number,
    step: number,
    location: string,
    language: string,
    languages: any [],
    availableLocations: any[],
    customLocations: any[],
    selectedCount: () => number
    updateOptionSelected: (index: number, isSelected: boolean) => void
    updateSelectAllOPtions: ( isSelected: boolean) => void
    updateStep: (step: number) => void
    // increase: (by: number) => void,
    startGame: (players: number , spies:number, lang:string) => void
    updatePlayerShown: (player: number) => void,
    onChangeLanguage: (index: number) => void,
    addCustomLocation: () => void,
    updateCustomLocation: (index:number,location:string) => void,
    updateCustomLocationSelected: (index: number, isSelected: boolean) => void
    removeCustomLocation: (index: number) => void,
    
  }
  
  declare global {
    interface Window { // ⚠️ notice that "Window" is capitalized here
      gtag: any;
    }
  }
  
  export const useGameStore = create<GameState>()((set,get) => ({
    players: [],
    step:0,
    spies: 1,
    location: '',
    language: 'EN',
    availableLocations: languages[0].locations.map(l=>({place:l,isSelected:true})),
    languages: languages.map((lang)=>({text:lang.text,value:lang.val})),
    customLocations: [],
    selectedCount: () => {
      return get().availableLocations.filter(l=>l.isSelected).length;
    },
    updateStep: (step) => set((state) => ({step})),
    onChangeLanguage: (index) => set((state) => ({ availableLocations: languages[index].locations.map(l=>({place:l,isSelected:true})) })),
    //increase: (by) => set((state) => ({ bears: state.bears + by })),
    startGame: (players,spies,lang) => { 
      const availableLocations = [...get().availableLocations.filter(l=>l.isSelected).map(l=>l.place),...get().customLocations.filter(l=>l.isSelected && l.place !== '').map(l=>l.place)];
      const { players: newPlayers , theLocation } = createGameState(players,spies,availableLocations);
      if (window.gtag){
        window.gtag('event','game-start',{
          players,
          spies,
          lang
        })
      } 
      set((state) => ({ players: newPlayers , step: 1 , spies , location: theLocation, language: lang}))
    },
    updateOptionSelected: (optionIndex,isSelected) => {
      set((state) => {
        const updatedOption = state.availableLocations.map((p,i)=>{ return i === optionIndex ? {...p,isSelected} : p});
        return { availableLocations: updatedOption }
      })
    },
    updateSelectAllOPtions: (isSelected) => {
      set((state) => {
        const updatedOption = state.availableLocations.map((p,i)=>{ return {...p,isSelected} });
        return { availableLocations: updatedOption }
      })
    },
    updatePlayerShown: (playerIndex) => {
      set((state) => {
        const updatedPlayers = state.players.map((p,i)=>{ return i === playerIndex ? {...p,shown:true} : p});
        const isAllPlayersDone = !updatedPlayers.some(p=>!p.shown);
        return { players: updatedPlayers, step : isAllPlayersDone ? 2 : 1 }
      })
    },
    addCustomLocation: () => set((state) => ({customLocations: [...state.customLocations, {place : '',isSelected:true} ]})),
    updateCustomLocation: (index,location:string) => {
      set((state) => {
        const updatedOption = state.customLocations.map((p,i)=>{ return i === index ? {...p,place: location} : p});
        return { customLocations: updatedOption }
      })
    },
    updateCustomLocationSelected: (optionIndex,isSelected) => {
      set((state) => {
        const updatedOption = state.customLocations.map((p,i)=>{ return i === optionIndex ? {...p,isSelected} : p});
        return { customLocations: updatedOption }
      })
    },
    removeCustomLocation: (index: number) => {
      set((state) => {
        const updatedOption = state.customLocations.filter((p,i)=>{ return i !== index });
        return { customLocations: updatedOption }
      })
    },
  }))
export const locationsZh = [
    '公园',
    '海滩',
    '博物馆',
    '图书馆',
    '咖啡店',
    '电影院',
    '购物中心',
    '动物园',
    '健身房',
    '游乐场',
    '体育场',
    '餐厅',
    '酒吧',
    '杂货店',
    '面包店',
    '学校',
    '医院',
    '教堂',
    '清真寺',
    '庙宇',
    '湖泊',
    '河流',
    '农场',
    '酒店',
    '汽车旅馆',
    '度假村',
    '游乐园',
    '水族馆',
    '赌场',
    '保龄球馆',
    '滑雪胜地',
    '露营地',
    '远足径',
    '植物园',
    '葡萄园',
    '酒庄',
    '啤酒厂',
    '美食车',
    '野餐区',
    '书店',
    '艺术画廊',
    '音乐厅',
    '夜总会',
    '卡拉OK酒吧',
    '社区中心',
    '体育俱乐部',
    '游泳池',
    '水疗中心',
    '美容院',
    '理发店',
    '瑜伽工作室',
    '舞蹈工作室',
    '拳击馆',
    '网球场',
    '高尔夫球场',
    '高尔夫练习场',
    '射击场',
    '密室逃脱',
    '蹦床公园',
    '攀岩馆',
    '彩弹场',
    '卡丁车场',
    '激光射击场',
    '武术学校',
    '棒球场',
    '篮球场',
    '足球场',
    '网球场',
    '排球场',
    '剧院',
    '观景点',
    '历史遗迹',
    '国家公园',
    '自然保护区',
    '森林',
    '山',
    '山丘',
    '洞穴',
    '瀑布',
    '温泉',
    '沙漠',
    '山洞',
    '灯塔',
    '天文台',
    '城堡',
    '豪宅',
    '宫殿',
    '桥',
    '大坝',
    '港口',
    '码头',
    '码头',
    '机场',
    '火车站',
    '汽车站',
    '地铁站',
    '出租车站',
    '租车服务',
    '加油站',
    '停车场',
    '街头市场',
    '购物中心',
    '奥特莱斯',
    '农贸市场',
    '跳蚤市场',
    '古董店',
    '二手店',
    '超市',
    '便利店',
    '五金店',
    '宠物店',
    '玩具店',
    '音乐店',
    '电子商店',
    '家具店',
    '家居装修店',
    '园艺中心',
    '宠物美容',
    '兽医诊所',
    '药店',
    '邮局',
    '银行',
    '比萨店',
    '冰淇淋店',
    '警察局',
    '消防局',
    '大学校园',
    '办公楼',
    '植物',
    '潜水艇',
];

export const locationsEn = [
    'Park',
    'Beach',
    'Museum',
    'Library',
    'Coffee shop',
    'Movie theater',
    'Mall',
    'Zoo',
    'Gym',
    'Playground',
    'Stadium',
    'Restaurant',
    'Bar',
    'Grocery store',
    'Bakery',
    'School',
    'Hospital',
    'Church',
    'Mosque',
    'Temple',
    'Lake',
    'River',
    'Farm',
    'Hotel',
    'Motel',
    'Resort',
    'Amusement park',
    'Aquarium',
    'Casino',
    'Bowling alley',
    'Ski resort',
    'Campground',
    'Hiking trail',
    'Botanical garden',
    'Vineyard',
    'Winery',
    'Brewery',
    'Food truck',
    'Picnic area',
    'Bookstore',
    'Art gallery',
    'Concert hall',
    'Nightclub',
    'Karaoke bar',
    'Community center',
    'Sports club',
    'Swimming pool',
    'Spa',
    'Beauty salon',
    'Barbershop',
    'Yoga studio',
    'Dance studio',
    'Boxing gym',
    'Tennis court',
    'Golf course',
    'Driving range',
    'Shooting range',
    'Escape room',
    'Trampoline park',
    'Rock climbing gym',
    'Paintball arena',
    'Go-kart track',
    'Laser tag arena',
    'Martial arts studio',
    'Baseball field',
    'Basketball court',
    'Soccer field',
    'Tennis court',
    'Volleyball court',
    'theater',
    'Scenic viewpoint',
    'Historical site',
    'National park',
    'Nature reserve',
    'Forest',
    'Mountain',
    'Hill',
    'Cavern',
    'Waterfall',
    'Hot spring',
    'Desert',
    'Cave',
    'Lighthouse',
    'Astronomical observatory',
    'Castle',
    'Mansion',
    'Palace',
    'Bridge',
    'Dam',
    'Port',
    'Marina',
    'Pier',
    'Airport',
    'Train station',
    'Bus station',
    'Subway station',
    'Taxi stand',
    'Car rental agency',
    'Gas station',
    'Parking lot',
    'Street market',
    'Shopping center',
    'Outlet mall',
    'Farmers market',
    'Flea market',
    'Antique store',
    'Thrift store',
    'Supermarket',
    'Convenience store',
    'Hardware store',
    'Pet store',
    'Toy store',
    'Music store',
    'Electronics store',
    'Furniture store',
    'Home improvement store',
    'Garden center',
    'Pet grooming salon',
    'Veterinary clinic',
    'Pharmacy',
    'Post office',
    'Bank',
    'pizzeria',
    'ice cream shop',
    'Police Station',
    'Fire Station',
    'University campus',
    'office building',
    'plan',
    'submarine',
  ];
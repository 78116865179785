import { Button, Modal } from 'react-bootstrap';

export function RulesModal(props:any) {
  // console.log("Render Bottom");
  const {showRules , handleCloseRules} = props;
  return (
    
      <Modal show={showRules} onHide={handleCloseRules} fullscreen>
        <Modal.Header closeButton>
          Game rules
        </Modal.Header>
        <Modal.Body>
        <p>
          <h3 className='fs-3'>Game Start</h3>
          <ul >
            <li>At the beginning of the game, each player selects and sees one ticket.</li>
            <li>The number of tickets available is equal to the number of players in the game.</li>
            <li>All tickets have the same random location, except for a specified number of selected players who will not know the location. These players will see '???' instead of a specific location on their tickets.</li>
            <li>The players who don't know the location are called 'spies'</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Game Objective</h3>
          <ul >
            <li>Players who know the location aim to reveal the spies</li>
            <li>Spies aim to reveal the location or mislead other players into thinking someone else doesn't know the location.</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Turns</h3>
          <ul >
            <li>The first player that want to start, selects another player and asks them a question about the location.</li>
            <li>The questioned player provides an answer.</li>
            <li>There is no group discussion or additional questions after the answer is given.</li>
            <li>After answering, the player who answered the question now takes their turn to ask another player (excluding the player who asked them) a question about the location.</li>
            <li>Players who know the location should aim to avoid making the questions and answers obvious, ensuring the spies remain uncertain.</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Elimination by Majority</h3>
          <ul >
            <li>At any time, the group can decide to point to any player as 'don't know' by a majority vote.</li>
            <li>The player with the majority vote is eliminated from the game.</li>
            <li>If the eliminated player is the only one or the last remaining spy, the other players win.</li>
            <li>If there are still spies, the game continues without the eliminated player.</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Elimination of Player Trying to Reveal Location</h3>
          <ul >
            <li>At any time, if a spy attempts to reveal the location and guesses correctly, all remaining spies win.</li>
            <li>If the guess is incorrect, the player making the guess is eliminated. If he is the last spy, the other players win.</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Winning Conditions</h3>
          <ul >
            <li>all spies being revealed.</li>
            <li>The number of eliminated players before the spies win is the same as the number of spies. ( But the game can keep going to try to revel the spy )</li>
          </ul>
          </p>
          <p>
          <h3 className='fs-3'>Example Scenarios</h3>
          <h4>Scenario 1</h4>
          <ul >
            <li>In a game with 6 players, 2 of them are in the dark about the location.</li>
            <li>After a series of rounds filled with intriguing questions, the majority consensus targets Player 4 for elimination. True to the suspicions, Player 4 genuinely doesn't know the location and is eliminated from the game.</li>
            <li>As the rounds progress, the players, seeking to uncover the remaining '???' player, decide to eliminate Player 3. To their dismay, Player 3, unfortunately, knew the location.</li>
            <li>With only one player left who doesn't know the location, they emerge victorious. The game could continue as players strive to unveil this last remaining mystery player.</li>
          </ul>
          <h4>Scenario 2</h4>
          <ul >
            <li>In a more intimate game with 4 players, only 1 is uncertain about the location.</li>
            <li>Following several rounds of thought-provoking questions, the spy takes a bold move and guesses the location.<br/>Miraculously, the guess is correct! - the spy win</li>
            <li>If the guess were incorrect, the player would face elimination, and the remaining players would claim victory.</li>
          </ul>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseRules}>
            Cool, let me play.
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

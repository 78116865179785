import { Button, Form, Modal } from 'react-bootstrap';
import { useGameStore } from './game-store';

export function LocationsListModal(props:any) {
  // console.log("Render Bottom");
  const {showModal , handleCLoseModal} = props;
  const availableLocations = useGameStore((state) => state.availableLocations);
  const updateOptionSelected = useGameStore((state) => state.updateOptionSelected);
  const updateSelectAllOPtions = useGameStore((state) => state.updateSelectAllOPtions);
  
  
  return (
    
      <Modal show={showModal} onHide={handleCLoseModal} fullscreen>
        <Modal.Header closeButton>
          Select places
          <Button variant="link"  onClick={()=>{updateSelectAllOPtions(true)}} className='ms-5'>select all</Button>
          <Button variant="link"  onClick={()=>{updateSelectAllOPtions(false)}} className='ms-5'>unselect all</Button>
        </Modal.Header>
        <Modal.Body>
      
          
          <div className='d-flex flex-row flex-wrap'>
            {availableLocations.map((location,index)=>{
              return <div key={index} style={{width:'250px', minWidth:'250px', cursor:'pointer'}} className='d-flex align-items-center flex-row' onClick={()=>{updateOptionSelected(index,!location.isSelected)}}> <Form.Check // prettier-ignore
                  onChange={()=>{}}
                  style={{cursor:'pointer'}}
                   type={'checkbox'}
                   checked={location.isSelected}
                  />
                  <span className='mx-2'>{location.place}</span>
                 </div>
            })}
          
          </div>
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCLoseModal}>
            Done.
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

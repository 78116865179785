export const locationsArabic = [
  'حديقة',
  'شاطئ',
  'متحف',
  'مكتبة',
  'مقهى',
  'سينما',
  'مول',
  'حديقة الحيوان',
  'صالة رياضية',
  'ملعب',
  'استاد',
  'مطعم',
  'بار',
  'متجر بقالة',
  'مخبز',
  'مدرسة',
  'مستشفى',
  'كنيسة',
  'مسجد',
  'معبد',
  'بحيرة',
  'نهر',
  'مزرعة',
  'فندق',
  'موتيل',
  'منتجع',
  'مدينة ملاهي',
  'حوض سمك',
  'كازينو',
  'صالة بولينج',
  'منتجع تزلج',
  'مخيم',
  'درب للتنزه',
  'حديقة نباتية',
  'كرم',
  'مصنع نبيذ',
  'مصنع جعة',
  'شاحنة طعام',
  'منطقة نزهة',
  'مكتبة',
  'صالة عرض فنية',
  'قاعة حفلات',
  'نادي ليلي',
  'بار كاريوكي',
  'مركز مجتمعي',
  'نادي رياضي',
  'مسبح',
  'منتجع صحي',
  'صالون تجميل',
  'صالون حلاقة',
  'استوديو اليوجا',
  'استوديو الرقص',
  'صالة البوكسينغ',
  'ملعب تنس',
  'ملعب جولف',
  'ميدان التدريب للغولف',
  'ميدان رماية',
  'غرفة الهروب',
  'حديقة الترامبولين',
  'صالة تسلق الصخور',
  'ساحة البينتبول',
  'مضمار سيارات الكارتينغ',
  'ساحة لعبة الليزر تاغ',
  'استوديو فنون القتال',
  'ملعب البيسبول',
  'ملعب كرة السلة',
  'ملعب كرة القدم',
  'ملعب تنس',
  'ملعب كرة الطائرة',
  'مسرح',
  'نقطة مشاهدة خلابة',
  'موقع تاريخي',
  'حديقة وطنية',
  'محمية طبيعية',
  'غابة',
  'جبل',
  'تل',
  'كهف',
  'شلال',
  'ينبوع ساخن',
  'صحراء',
  'كهف',
  'منارة',
  'مرصد فلكي',
  'قلعة',
  'قصر',
  'قصر',
  'جسر',
  'سد',
  'ميناء',
  'مارينا',
  'رصيف',
  'مطار',
  'محطة قطار',
  'محطة باصات',
  'محطة مترو',
  'موقف سيارات أجرة',
  'وكالة تأجير سيارات',
  'محطة وقود',
  'موقف سيارات',
  'سوق شارع',
  'مركز تسوق',
  'مول الأوتليت',
  'سوق الفلاحين',
  'سوق البرغوث',
  'متجر أنتيكات',
  'متجر للمستعمل',
  'سوبر ماركت',
  'متجر صغير',
  'متجر أدوات',
  'متجر حيوانات أليفة',
  'متجر ألعاب',
  'متجر موسيقى',
  'متجر إلكترونيات',
  'متجر أثاث',
  'متجر تحسين المنزل',
  'مركز حديقة',
  'صالون تزيين الحيوانات الأليفة',
  'عيادة بيطرية',
  'صيدلية',
  'مكتب بريد',
  'بنك',
  'بيتزيريا',
  'متجر آيس كريم',
  'محطة شرطة',
  'محطة إطفاء',
  'حرم جامعي',
  'مبنى مكاتب',
  'خطة',
  'غواصة'
];
export const locationsFr = [
  'Parc',
  'Plage',
  'Musée',
  'Bibliothèque',
  'Café',
  'Cinéma',
  'Centre commercial',
  'Zoo',
  'Gymnase',
  'Aire de jeux',
  'Stade',
  'Restaurant',
  'Bar',
  'Épicerie',
  'Boulangerie',
  'École',
  'Hôpital',
  'Église',
  'Mosquée',
  'Temple',
  'Lac',
  'Rivière',
  'Ferme',
  'Hôtel',
  'Motel',
  'Complexe touristique',
  `Parc d'attractions`,
  'Aquarium',
  'Casino',
  'Salle de bowling',
  'Station de ski',
  'Camping',
  'Sentier de randonnée',
  'Jardin botanique',
  'Vignoble',
  'Cave à vin',
  'Brasserie',
  'Camion-restaurant',
  'Aire de pique-nique',
  'Librairie',
  `Galerie d'art`,
  'Salle de concert',
  'Boîte de nuit',
  'Bar karaoké',
  'Centre communautaire',
  'Club sportif',
  'Piscine',
  'Spa',
  'Salon de beauté',
  'Salon de coiffure',
  'Studio de yoga',
  'Studio de danse',
  'Salle de boxe',
  'Court de tennis',
  'Terrain de golf',
  'Practice de golf',
  'Stand de tir',
  `Salle d'escape game`,
  'Parc de trampoline',
  `Salle d'escalade`,
  `Arène de paintball`,
  'Piste de karting',
  'Arène de laser game',
  `Studio d'arts martiaux`,
  'Terrain de baseball',
  'Terrain de basketball',
  'Terrain de football',
  'Court de tennis',
  'Terrain de volleyball',
  'Théâtre',
  'Point de vue panoramique',
  'Site historique',
  'Parc national',
  'Réserve naturelle',
  'Forêt',
  'Montagne',
  'Colline',
  'Grotte',
  `Chute d'eau`,
  'Source chaude',
  'Désert',
  'Caverne',
  'Phare',
  'Observatoire astronomique',
  'Château',
  'Manoir',
  'Palais',
  'Pont',
  'Barrage',
  'Port',
  'Marina',
  'Jetée',
  'Aéroport',
  'Gare ferroviaire',
  'Gare routière',
  'Station de métro',
  'Station de taxi',
  'Agence de location de voitures',
  'Station-service',
  'Parking',
  'Marché de rue',
  `Centre d'achats`,
  `Centre de magasins d'usine`,
  'Marché fermier',
  'Marché aux puces',
  `Magasin d'antiquités`,
  `Magasin d'occasion`,
  'Supermarché',
  'Supérette',
  'Quincaillerie',
  'Animalerie',
  'Magasin de jouets',
  'Magasin de musique',
  `Magasin d'électronique`,
  'Magasin de meubles',
  'Magasin de bricolage',
  'Jardinerie',
  'Salon de toilettage pour animaux',
  'Clinique vétérinaire',
  'Pharmacie',
  'Bureau de poste',
  'Banque',
  'Pizzeria',
  'Glacier',
  'Commissariat de police',
  'Caserne de pompiers',
  'Campus universitaire',
  'Bâtiment de bureaux',
  'Plante',
  'Sous-marin',
];

import React , {useState} from 'react';

import { Stack, Container, Button, Row, Form, Modal, Collapse } from 'react-bootstrap';
import HeaderLogo from '../../assets/header-logo.png';
import { RulesModal } from './Rules.modal';
import { useGameStore } from './game-store';
import { TimerCountdown } from './TimerCountdown';
import { LocationsListModal } from './LocationList.modal';


function App() {
  // console.log("Render App");
  
  return (
  <Container style={{maxWidth:'540px'}}>
    <Header />
    <StepSwitcher />
    {/* <GameTickets />
    <StartGamePanel />
    <div className="lineDivider" />
    <TimerCountdown /> */}
  </Container>
   
  );
}

function Header() {
  return (
    <>
      <img className='headerImage' src={HeaderLogo} alt="Which place"/>
      
      {/* <audio  id="alarm_audio" controls loop autoPlay src={'https://whichplace-85c67.web.app/static/alaram-sound.mp3'}></audio> */}
    </>
  
  )
}

function StepSwitcher() {
  const currentStep = useGameStore((state) => state.step);

  return(
    <>
      { currentStep === 0 && <StartGamePanel />}
      { currentStep === 1 && <GameTickets />}
      { currentStep === 2 && <GameProgress />}
      { currentStep === 3 && <GameEnd />}
    </>
  )
}

function StartGamePanel() {
  // console.log("Render Bottom");
  // const increasePopulation = useGameStore((state) => state.increase)
  const startGameStore = useGameStore((state) => state.startGame)
  const selectedLocationsCount = useGameStore((state) => state.selectedCount());
  const languages = useGameStore((state) => state.languages);
  const availableLocations = useGameStore((state) => state.availableLocations);
  const customLocations = useGameStore((state) => state.customLocations);
  
  const onChangeLanguage = useGameStore((state) => state.onChangeLanguage);
  const addCustomLocation = useGameStore((state) => state.addCustomLocation);
  
  // const 
  const [showRules, setShowRules] = useState(false);
  const handleCloseRules = () => setShowRules(false);
  const handleShowRules = () => setShowRules(true);
  const [showLocationList, setShowLocationList] = useState(false);
  const handleCloseLocationList = () => setShowLocationList(false);
  const handleShowLocationList = () => setShowLocationList(true);
  const [playersInput, setPlayersInput] = useState<number | string>(4);
  const [spiesInput, setSpiesInput] = useState<number | string>(1);
  
  const [langInput, setLangInput] = useState(0);

  const startGame = () => {
    if (selectedLocationsCount + customLocations.filter(c=>c.isSelected && c.place !== '').length > 2) {
    if (playersInput && spiesInput && +playersInput > +spiesInput ){
      startGameStore(+playersInput,+spiesInput,languages[langInput].text)
    } else {
      alert('Wrong input')
    }
    } else {
      alert('Please select at least 3 places or custom places')
    }
  }
  return (
    <div className='d-flex flex-column'>
      <div className='my-2 d-flex flex-column flex-sm-row'>
         <div className='mb-3'>
         <Form.Label htmlFor="playersNumber">Players:</Form.Label>
          <Form.Control id="playersNumber" type="number" placeholder="Players" value={playersInput} onChange={(e)=>{setPlayersInput(e.target.value ? +e.target.value : '')}}/>
         </div>
         <div className='mb-3'>
         <Form.Label htmlFor="spiesNumber">Spies:</Form.Label>
          <Form.Control type="number" id='spiesNumber' placeholder="Spies" value={spiesInput} onChange={(e)=>{setSpiesInput(e.target.value ? +e.target.value : '')}}/>
         </div>
         <div className='mb-3'>
      <Form.Label htmlFor="langInput">Language:</Form.Label>
      <Form.Select aria-label="Language" id='langInput' value={langInput} onChange={(e)=>{setLangInput(+e.target.value); onChangeLanguage(+e.target.value)}}>
        {languages.map((lang,ind)=>(
          <option key={ind} value={ind}>{lang.text}</option>
        ))}
        
        
       </Form.Select>
      </div>
         
      </div>
       
      <div>
        <Button  variant='outline-primary' onClick={()=>{handleShowLocationList()}}>{selectedLocationsCount} / {availableLocations.length} selected places ( click to edit )</Button>
        
          <div className='my-2'>
          {customLocations.map((location,index)=>{
            return <CustomLocationRow index={index} location={location}></CustomLocationRow>
          })}
          </div>

<Button className='my-2' onClick={()=>{addCustomLocation()}}>+ Add custom place</Button>
        
      </div>
        

      

      <div className='d-flex pb-2 align-items-center'>
          <Button as="a" variant="info" onClick={()=>{startGame()}}>
            Start game
          </Button>
          <Button variant="link"  onClick={()=>{handleShowRules()}} className='ms-5'>Rules?</Button>
          
      </div>
      <RulesModal showRules={showRules} handleCloseRules={handleCloseRules} />
      <LocationsListModal showModal={showLocationList} handleCLoseModal={handleCloseLocationList} />
    </div>

  );
}


function CustomLocationRow(props:any) {
  const {location , index} = props;
  // console.log("Render Ticket " + (index+1));
  const updateCustomLocation = useGameStore((state) => state.updateCustomLocation);
  const updateCustomLocationSelected = useGameStore((state) => state.updateCustomLocationSelected);
  const removeCustomLocation = useGameStore((state) => state.removeCustomLocation);
  
  return (
    <>
        <div className='d-flex my-1 align-items-stretch' key={index}>
          <div className='d-flex flex-column px-2'style={{cursor:'pointer'}}  onClick={()=>{updateCustomLocationSelected(index,!location.isSelected)}}>
        <Form.Check // prettier-ignore
      
      style={{cursor:'pointer'}}
           type={'checkbox'}
           className='my-1'
           checked={location.isSelected}
         />
         </div>
        <Form.Control className='mx-2' type="text" placeholder="Add place..." value={location.place} onChange={(e)=>{updateCustomLocation(index, e.target.value )}} />

          <Button  onClick={()=>{removeCustomLocation(index)}} >
          X
          </Button>
      </div>

    </>
  );
}

function GameTickets() {
  // console.log("Render Tickets");
  const players = useGameStore((state) => state.players)
  // const updateShown = useGameStore((state) => state.updatePlayerShown)
  return (
    players.length ? 
    <>
    <Row>       
        <Stack gap={3} className='mt-4'>
      {players.map((player,index)=>{
      return (
        <GameTicket key={index} index={index} player={player} />
      )
    })}
    </Stack>

    </Row> 
    <div className="lineDivider my-3" />
    <Row>
      <h3 >Let's go!</h3>
      <p >Each player, in turn, selects one ticket without revealing its content to others.</p>
      <p>* = The player who start the game</p>
    </Row>
    </> : null
  )
}

function GameTicket(props:any) {
  const {player , index} = props;
  // console.log("Render Ticket " + (index+1));
  const updateShown = useGameStore((state) => state.updatePlayerShown)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
        <div className='d-flex align-items-stretch' key={index}>
          <Button className='w-100' size='lg' variant={player.shown ? "info" : "success" } onClick={()=>{if (!player.shown){ handleShow() }}} >
          {player.shown && player.starting ? <span className='me-2'>*</span> : null}
            Ticket {index+1} 
            {player.shown && player.starting ? <span className='ms-2'>*</span> : null}
            {/* {player.shown && player.starting && <span>*</span>} */}
            {/* , Place: {player.location} , { player.shown? 'Y' : 'N'} */}
          </Button>
      </div>
      <Modal show={show} onHide={()=>{handleClose();updateShown(index)}}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {player.location === '???' ? <>
          🕵️ You are a spy! 🕵️
          </> : <>
          Place: {player.location} 
          </>}
          <br/>
          Ask first question: {player.starting ? 'Yes! 🙂' : 'No 🙄' }
          </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{handleClose();updateShown(index)}}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

function GameProgress() {

  const [showRules, setShowRules] = useState(false);
  const [isQuickRulesOpen, setIsQuickRulesOpen] = useState(true);
  const handleCloseRules = () => setShowRules(false);
  const handleShowRules = () => setShowRules(true);
  const updateStep = useGameStore((state) => state.updateStep)

  const endGame = () => {
    updateStep(3);
  }

  return (
    <Row>
    <h3 className=''>Let's find the spy!</h3>
    <div className="lineDivider" />
    <TimerCountdown />
    <div className="lineDivider" />
    <div className='d-flex mt-3 align-items-center flex-row justify-content-between'>
          <Button variant={isQuickRulesOpen? "info" : "outline-info"} onClick={()=>{setIsQuickRulesOpen(!isQuickRulesOpen)}}>
            Quick rules
          </Button>
          <Button variant="outline-info" onClick={()=>{handleShowRules()}}>
            Full rules
          </Button>
          <Button variant="outline-danger" onClick={()=>{endGame()}}>
            End game
          </Button>
    </div>
    <Collapse in={isQuickRulesOpen}>
      <div>
        <div className="lineDivider my-3" />
        <p>
      Each player, in turn, ask any other player question about the place, trying to understand if the player is the spy.. but avoid making the questions and answers obvious, so the location will not reveal.
      <br/>
      The player who answered the question now takes their turn to ask another player (excluding the player who asked them)
        </p>
      </div>
        
    </Collapse>
    <RulesModal showRules={showRules} handleCloseRules={handleCloseRules} />
    
  </Row>
  )
}

function GameEnd() {

  
  const updateStep = useGameStore((state) => state.updateStep);
  const startGameStore = useGameStore((state) => state.startGame);

  const players = useGameStore((state) => state.players);
  const theLocation = useGameStore((state) => state.location);
  const language = useGameStore((state) => state.language);
  const spies = useGameStore((state) => state.spies);

  // const endGame = () => {
  //   updateStep(2);
  // }

  const startNewGame = () => {
    startGameStore(players.length,spies,language)
  }

  const changeSettings = () => {
    updateStep(0);
  }

  return (
    <Row>
    <h3 className=''>Game ended!</h3>
    <div className="lineDivider" />
    <p className='text-center mt-3'>
      The location: {theLocation}
      <br/>
      The sp{spies > 1 ? 'ies' : 'y'}: {players.filter(p=>p.location==='???').map(p=>('Ticket ' + (p.index+1))).join(', ')}
    </p>
    
    <div className="lineDivider" />
    <div className='d-flex mt-3 align-items-center flex-row justify-content-around'>
          <Button variant="outline-primary" onClick={()=>{startNewGame()}}>
            New game
          </Button>
          <Button variant="outline-primary" onClick={()=>{changeSettings()}}>
            Change settings
          </Button>
    </div>

    
  </Row>
  )
}


export default App;

export const locationsRu = [
    'Парк',
    'Пляж',
    'Музей',
    'Библиотека',
    'Кофейня',
    'Кинотеатр',
    'Торговый центр',
    'Зоопарк',
    'Спортзал',
    'Детская площадка',
    'Стадион',
    'Ресторан',
    'Бар',
    'Продуктовый магазин',
    'Булочная',
    'Школа',
    'Больница',
    'Церковь',
    'Мечеть',
    'Храм',
    'Озеро',
    'Река',
    'Ферма',
    'Отель',
    'Мотель',
    'Курорт',
    'Парк аттракционов',
    'Аквариум',
    'Казино',
    'Боулинг',
    'Горнолыжный курорт',
    'Кемпинг',
    'Тропа для хайкинга',
    'Ботанический сад',
    'Виноградник',
    'Винодельня',
    'Пивоварня',
    'Фуд-трак',
    'Пикниковая зона',
    'Книжный магазин',
    'Художественная галерея',
    'Концертный зал',
    'Ночной клуб',
    'Караоке-бар',
    'Коммьюнити-центр',
    'Спортивный клуб',
    'Бассейн',
    'Спа',
    'Салон красоты',
    'Парикмахерская',
    'Студия йоги',
    'Танцевальная студия',
    'Боксерский зал',
    'Теннисный корт',
    'Гольф-поле',
    'Полигон для гольфа',
    'Стрельбище',
    'Квест-комната',
    'Парк на батутах',
    'Скалодром',
    'Площадка для пейнтбола',
    'Гоночная трасса для картинга',
    'Арена для лазертага',
    'Студия боевых искусств',
    'Бейсбольное поле',
    'Баскетбольная площадка',
    'Футбольное поле',
    'Теннисный корт',
    'Волейбольная площадка',
    'Театр',
    'Смотровая площадка',
    'Исторический памятник',
    'Национальный парк',
    'Природный заповедник',
    'Лес',
    'Гора',
    'Холм',
    'Пещера',
    'Водопад',
    'Горячий источник',
    'Пустыня',
    'Пещера',
    'Маяк',
    'Астрономическая обсерватория',
    'Замок',
    'Усадьба',
    'Дворец',
    'Мост',
    'Дамба',
    'Порт',
    'Марина',
    'Пристань',
    'Аэропорт',
    'Железнодорожная станция',
    'Автобусная станция',
    'Станция метро',
    'Остановка такси',
    'Агентство проката автомобилей',
    'Заправочная станция',
    'Парковка',
    'Уличный рынок',
    'Торговый центр',
    'Аутлет',
    'Фермерский рынок',
    'Блошиный рынок',
    'Магазин антиквариата',
    'Магазин б/у товаров',
    'Супермаркет',
    'Магазин у дома',
    'Строительный магазин',
    'Зоомагазин',
    'Магазин игрушек',
    'Музыкальный магазин',
    'Магазин электроники',
    'Мебельный магазин',
    'Магазин товаров для дома',
    'Садовый центр',
    'Салон для ухода за животными',
    'Ветеринарная клиника',
    'Аптека',
    'Почтовое отделение',
    'Банк',
    'Пиццерия',
    'Мороженое',
    'Полицейский участок',
    'Пожарная станция',
    'Университетский кампус',
    'Офисное здание',
    'Растение',
    'Подводная лодка',
];

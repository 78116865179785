import { locationsHindi } from "./locations-hindi";
import { locationsArabic } from "./locations-arabic";
import { locationsEn } from "./locations-en";
import { locationsEs } from "./locations-es";
import { locationsFr } from "./locations-fr";
import { locationsHebrew } from "./locations-heb";
import { locationsRu } from "./locations-ru";
import { locationsZh } from "./locations-chinese";

export const languages = [
  {text:'English' , val : 'en' , locations: locationsEn},
  {text:'Arabic' , val: 'arb' , locations: locationsArabic},
  {text:'French', val: 'fr' , locations: locationsFr},
  {text:'Hebrew' , val: 'he' , locations: locationsHebrew},
  {text:'Hindi' , val: 'hin' , locations: locationsHindi},
  {text:'Spanish', val: 'es' , locations: locationsEs},
  {text:'Russian', val: 'es' , locations: locationsRu},
  {text:'Chinese (Simplified)', val: 'chin' , locations: locationsZh},
];




import React , {useState, useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import Countdown from 'react-countdown';
import useSound from 'use-sound';

export function TimerCountdown() {
    const [minutesInput, setMinutesInput] = useState<number | string>(8);
    const [timeLeftMilliseconds, setTimeLeftMilliseconds] = useState<number>(480000);
    const [isTimerWorking, setIsTimerWorking] = useState<boolean>(false);
    const [isTimerPause, setIsTimerPause] = useState<boolean>(false);
    const [isTimerEnd, setIsTimerEnd] = useState<boolean>(false);
    const timerRef = useRef<any>(null);
  
    const [play , {stop}] = useSound('https://whichplace-85c67.web.app/static/alaram-sound.mp3');
  
    const startTimer = () => {
      setTimeLeftMilliseconds(+minutesInput * 60000)
      setIsTimerWorking(true)
    }  
  
    const pausePlayTimer = () => {
      if(timerRef.current){
        //console.log(timerRef.current);
        if (isTimerPause){
          timerRef.current.start();
        } else {
          const timePast = Date.now() - timerRef.current.initialTimestamp;
          setTimeLeftMilliseconds(timeLeftMilliseconds-timePast);
          timerRef.current.pause();
        }
        setIsTimerPause(!isTimerPause);
        // timerRef.current.pause();
      }
    }
  
    const timerComplete = () => {
      setIsTimerEnd(true);
      play();
    }
  
    const resetTimer = () => {
      setIsTimerWorking(false); 
      setIsTimerPause(false); 
      setIsTimerEnd(false);
      stop();
    }
  
    return (
      <div className='d-flex flex-column'>
    
        <Form.Label htmlFor="playersNumber" className='fs-5 mt-2'>Timer</Form.Label>
        {!isTimerWorking ?
        
        <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-end'>
          {/* <div className='my-2 d-flex flex-row align-items-center'>
            
          </div> */}
          <div className='mb-3'>
            <div className='d-flex flex-row align-items-center'>
            <Form.Control id="playersNumber" type="number" placeholder="" value={minutesInput} onChange={(e)=>{setMinutesInput(e.target.value ? +e.target.value : '')}}/>
            <div className='ms-2'>Minutes</div>
            </div>
          </div>
          <div className='mb-3 ms-sm-3'>
            <Button as="a" variant="info" onClick={()=>{startTimer()}} >
              Start
            </Button>
          </div>
        </div> : <div className='my-2 d-flex flex-row'> 
          <Button variant='outline-dark' disabled>
            <Countdown date={Date.now() + timeLeftMilliseconds} ref={timerRef} onComplete={()=>{timerComplete()}} />
          </Button>
            
            {isTimerEnd ? 
              <Button variant='outline-dark ms-2' disabled>Round END</Button> : 
              <Button variant='outline-dark ms-2' onClick={()=>{pausePlayTimer()}}>{isTimerPause ? 'Play' : 'Pause'}</Button> 
            }
            <Button variant='outline-dark ms-2'  onClick={()=>{resetTimer()}}>Reset</Button>
        </div>
        }
          
        
      </div>
    )
  }
  
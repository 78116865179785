export const locationsEs = [
  'Parque',
  'Playa',
  'Museo',
  'Biblioteca',
  'Cafetería',
  'Cine',
  'Centro comercial',
  'Zoológico',
  'Gimnasio',
  'Parque infantil',
  'Estadio',
  'Restaurante',
  'Bar',
  'Tienda de comestibles',
  'Panadería',
  'Escuela',
  'Hospital',
  'Iglesia',
  'Mezquita',
  'Templo',
  'Lago',
  'Río',
  'Granja',
  'Hotel',
  'Motel',
  'Resort',
  'Parque de atracciones',
  'Acuario',
  'Casino',
  'Bolera',
  'Resort de esquí',
  'Camping',
  'Sendero de senderismo',
  'Jardín botánico',
  'Viñedo',
  'Bodega',
  'Cervecería',
  'Camión de comida',
  'Área de picnic',
  'Librería',
  'Galería de arte',
  'Sala de conciertos',
  'Club nocturno',
  'Bar de karaoke',
  'Centro comunitario',
  'Club deportivo',
  'Piscina',
  'Spa',
  'Salón de belleza',
  'Barbería',
  'Estudio de yoga',
  'Estudio de danza',
  'Gimnasio de boxeo',
  'Cancha de tenis',
  'Campo de golf',
  'Campo de práctica de golf',
  'Campo de tiro',
  'Sala de escape',
  'Parque de trampolines',
  'Gimnasio de escalada',
  'Arena de paintball',
  'Pista de karts',
  'Arena de laser tag',
  'Estudio de artes marciales',
  'Campo de béisbol',
  'Cancha de baloncesto',
  'Campo de fútbol',
  'Cancha de tenis',
  'Cancha de voleibol',
  'Teatro',
  'Mirador panorámico',
  'Sitio histórico',
  'Parque nacional',
  'Reserva natural',
  'Bosque',
  'Montaña',
  'Colina',
  'Caverna',
  'Cascada',
  'Manantial caliente',
  'Desierto',
  'Cueva',
  'Faro',
  'Observatorio astronómico',
  'Castillo',
  'Mansión',
  'Palacio',
  'Puente',
  'Presa',
  'Puerto',
  'Marina',
  'Muelle',
  'Aeropuerto',
  'Estación de tren',
  'Estación de autobuses',
  'Estación de metro',
  'Parada de taxi',
  'Agencia de alquiler de coches',
  'Gasolinera',
  'Estacionamiento',
  'Mercado callejero',
  'Centro comercial',
  'Centro outlet',
  'Mercado de agricultores',
  'Mercado de pulgas',
  'Tienda de antigüedades',
  'Tienda de segunda mano',
  'Supermercado',
  'Tienda de conveniencia',
  'Ferretería',
  'Tienda de mascotas',
  'Tienda de juguetes',
  'Tienda de música',
  'Tienda de electrónica',
  'Tienda de muebles',
  'Tienda de mejoras para el hogar',
  'Centro de jardinería',
  'Salón de aseo para mascotas',
  'Clínica veterinaria',
  'Farmacia',
  'Oficina de correos',
  'Banco',
  'Pizzería',
  'Heladería',
  'Estación de policía',
  'Estación de bomberos',
  'Campus universitario',
  'Edificio de oficinas',
  'Planta',
  'Submarino',
];
export const createGameState = (players:number, spies:number, availableLocations:string[]) => {
    const theSpies = getRandomNumbers(spies, players);
    const theLocation = availableLocations[Math.floor(Math.random() * availableLocations.length)];
    const startedPlayerIndex = Math.floor(Math.random() * players);
    // console.log(startedPlayer);
    let thePlayers = [];
    for (let index = 0; index < players; index++) {
        const isSpy = theSpies?.includes(index+1);
        thePlayers.push({
            index,
            location : isSpy ? '???' : theLocation,
            shown: false,
            starting: startedPlayerIndex === index
        })
        // const element = array[index];
    }
    return {players : thePlayers , theLocation} ;

}

function getRandomNumbers(n: number, x: number): number[] | null {
    if (n > x) {
        console.error("Cannot select more unique numbers than the range allows.");
        return null;
    }

    const selectedNumbers: number[] = [];
    const availableNumbers: number[] = [];

    // Fill availableNumbers array with numbers from 1 to x
    for (let i = 1; i <= x; i++) {
        availableNumbers.push(i);
    }

    // Select n unique random numbers
    for (let i = 0; i < n; i++) {
        const randomIndex = Math.floor(Math.random() * availableNumbers.length);
        const selectedNumber = availableNumbers.splice(randomIndex, 1)[0];
        selectedNumbers.push(selectedNumber);
    }

    return selectedNumbers;
}
export const locationsHebrew = [
  'פארק',
  'חוף',
  'מוזיאון',
  'ספריה',
  'בית קפה',
  'בית קולנוע',
  'מרכז קניות',
  'גן חיות',
  'חדר כושר',
  'גן שעשועים',
  'אצטדיון',
  'מסעדה',
  'בר',
  'מאפייה',
  'בית ספר',
  'בית חולים',
  'כנסייה',
  'מסגד',
  'מקדש',
  'אגם',
  'נהר',
  'חווה',
  'מלון',
  'אחסנייה',
  'ריזורט',
  'פארק שעשועים',
  'אקווריום',
  'קזינו',
  'באולינג',
  'מתחם סקי',
  'אתר קמפינג',
  'שביל הליכה',
  'גן בוטני',
  'כרם',
  'יקב',
  'מבשלה',
  'משאיות מזון',
  'אזור פיקניק',
  'חנות ספרים',
  'גלריה לאמנות',
  'אולם הופעות',
  'מועדון לילה',
  'בר קריוקי',
  'מרכז קהילתי',
  'מועדון ספורט',
  'בריכת שחייה',
  'ספא',
  'סלון יופי',
  'מספרה',
  'סטודיו יוגה',
  'סטודיו ריקוד',
  'מועדון אגרוף',
  'מגרש טניס',
  'מגרש גולף',
  'מסלול נהיגה',
  'מטווח',
  'חדר בריחה',
  'פארק טרמפולינות',
  'מכון טיפוס סלעים',
  'מגרש פיינטבול',
  'קארטינג',
  'מגרש לייזר טאג',
  'סטודיו לאמנויות לחימה',
  'מגרש בייסבול',
  'מגרש כדורסל',
  'מגרש כדורגל',
  'מגרש טניס',
  'מגרש כדורעף', 
  'תיאטרון',
  'נקודת תצפית נוף',
  'אתר היסטורי',
  'פארק לאומי',
  'שמורת טבע',
  'יער',
  'הר',
  'גבעה',
  'מערה',
  'מפל',
  'מעיין חם',
  'מדבר',
  'מערה',
  'מגדלור',
  'מצפה כוכבים אסטרונומי',
  'טירה',
  'אחוזה',
  'ארמון',
  'גשר',
  'סכר',
  'נמל',
  'מרינה',
  'אגם',
  'שדה תעופה',
  'תחנת רכבת',
  'תחנת אוטובוס',
  'תחנת מטרו',
  'תחנת מוניות',
  'סוכנות השכרת רכבים',
  'תחנת דלק',
  'חניון',
  'שוק רחוב',
  'קניון',
  'חנות עודפים',
  'שוק חקלאים',
  'שוק הפשפשים',
  'חנות עתיקות',
  'חנות יד שנייה',
  'סופרמרקט',
  'חנות נוחות',
  'חנות כלי בית',
  'חנות חיות',
  'חנות צעצועים',
  'חנות מוזיקה',
  'חנות אלקטרוניקה',
  'חנות רהיטים',
  'משתלה',
  'סלון טיפוח חיות מחמד',
  'מרפאה וטרינרית',
  'בית מרקחת',
  'דואר',
  'בנק',
  'קיוסק',
  'פיצריה',
  'גלידריה',
  'תחנת משטרה',
  'תחנת כבאות',
  'קמפוס אוניברסיטאי',
  'בניין משרדים',
  'מטוס',
  'צוללת'
]